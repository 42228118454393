@import '_variables';
main {
  overflow: hidden;
}
.main {
  color: $white;
  padding-top: $header-lg-height;
  min-height: 100vh;
  background: #177ebf url(../../img/RV_TEXTURE_CONCRETE-WALL.png) no-repeat
    center;
  background-size: cover;
  padding-bottom: 350px;
  position: relative;
  &--content {
    position: relative;
    z-index: 2;
  }
}
.circle-1 {
  width: 18.07%;
  position: absolute;
  right: 0;
  top: 0;
}
.circle-2 {
  width: 10.625%;
  position: absolute;
  right: 15.26%;
  bottom: 0;
}
.circle-3 {
  width: 15.625%;
  position: absolute;
  left: 14.27%;
  bottom: 166px;
}
.eu-donated {
  max-width: 820px;
  width: 100%;
  font-size: 14px;
  margin: 0 auto;
}
header {
  z-index: 10;
  height: $header-lg-height;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  transition: 0.4s background ease-out;
  .hamburger {
    border: none;
    height: 35px;
    width: 35px;
    outline: none;
    padding: 8.5px 6px;
    background: transparent;
    .span-wrapper {
      position: relative;
      height: 100%;
      span {
        border-radius: 4px;
        position: absolute;
        width: 100%;
        height: 2px;
        display: block;
        background: $grey-1;
        left: 50%;
        transition: 0.3s all ease-out;
        transform: translateY(0) translateX(-50%) rotate(0);
        &:first-child {
          top: 0;
        }
        &:nth-child(2) {
          opacity: 1;
          top: 50%;
          transform: translateY(-50%) translateX(-50%) rotate(0);
        }
        &:last-child {
          top: initial;
          bottom: 0;
        }
      }
    }
  }
  &.opened {
    .hamburger {
      .span-wrapper {
        span {
          &:first-child {
            top: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(45deg);
          }
          &:nth-child(2) {
            width: 0;
            opacity: 0;
          }
          &:last-child {
            bottom: initial;
            top: 50%;
            transform: translateY(-50%) translateX(-50%) rotate(-45deg);
          }
        }
      }
    }
  }
  svg {
    path {
      transition: 0.3s all linear;
    }
  }
  .btn-primary {
    height: 40px;
    font-weight: normal;
    margin-left: 24px;
  }
  &.static {
    svg {
      path {
        fill: $white;
      }
    }
    ul {
      li {
        > a:not(.btn) {
          &:hover {
            color: $grey-1;
          }
        }
      }
    }
    .btn-primary {
      background: $white;
      border-color: $white;
      color: $blue-500;
    }
  }
  &.static-pinned {
    background: $white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    .login-btn {
      > a:not(.btn) {
        color: $blue-500;
      }
    }
    ul {
      li {
        > a:not(.btn) {
          color: $grey-1;
          &:hover {
            color: $blue-300;
          }
        }
      }
    }
  }

  .logo {
    margin-right: 24px;
  }
  ul {
    li {
      > a:not(.btn) {
        color: $white;
        line-height: 40px;
        padding: 0 24px;
        transition: 0.25s color ease;
        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
}
.accepting {
  img {
    width: 70.83%;
  }
}
.texture-laptop {
  img {
    width: 69.27%;
    z-index: 3;
    position: relative;
    margin-top: -300px;
  }
}
.h1 {
  font-weight: 500;
  font-size: 72px;
  line-height: 94px;
  letter-spacing: 1px;
  margin-top: 97px;
}

.h2 {
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 1px;
  opacity: 0.8;
  margin-bottom: 32px;
}

.download-section {
  padding-top: 94px;
  background: linear-gradient(114.03deg, $blue-400 0%, $blue-500 100%);
  box-shadow: inset -2px 0px 2px rgba(63, 83, 110, 0.15);
  ul {
    z-index: 3;
    position: relative;
    margin-bottom: 62px;
    li {
      margin: 0 10.5px;
    }
  }
  .bottom-img {
    position: relative;
    margin: 0 auto;
    margin-top: -90px;
    width: 86.35%;
    span {
      z-index: 1;
      top: 17%;
      font-size: 0.8vw;
      line-height: 1vw;
      text-align: left;
      left: 0;
      background: #10557F;
      mix-blend-mode: normal;
      border: 1px solid rgba(255, 255, 255, 0.5);
      box-sizing: border-box;
      border-radius: 6px;
      position: absolute;
      padding: 1vw;
      max-width: 10.5vw;
      &:after {
        content: '';
        position: absolute;
        background: url(../../img/oval.svg) no-repeat center;
        background-size: 95% 95%;
        width: 1.7vw;
        height: 1.7vw;
        top: 50%;
        transform: translateY(-50%);
        right: -3vw;
      }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: -0.1vw;
        height: 0.5vw;
        width: 0.5vw;
        background: #10557F;
        mix-blend-mode: normal;
        border: 1px solid rgba(255, 255, 255, 0.5);
        box-sizing: border-box;
        transform: rotate(45deg) translateY(-50%);
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }
  &-small {
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  &-title {
    font-weight: 300;
    b {
      font-weight: bold;
    }
  }
}

footer {
  height: 66px;
  background: $white;
  padding: 0 36px;
  span {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.4px;
    color: $grey-4;
  }
  ul {
    li {
      margin-right: 64px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  a:not(.btn) {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.4px;
    text-decoration: underline;
    color: $blue-500;
    &:hover {
      text-decoration: none;
    }
  }
}

.without-paper {
  padding-top: 124px;
  padding-bottom: 93px;
  &-wrapper {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
  }

  .text {
    margin: 0;
    max-width: 650px;
    width: 100%;
    color: $grey-4;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
  }
}
.usage {
  padding-bottom: 442px;
  padding-top: 144px;
  .title {
    margin-bottom: 77px;
  }
  &-item {
    padding-left: 20px;
  }
  &-holder {
    margin-bottom: 120px;
  }
  .usage-title {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 1px;
    color: $grey-1;
    margin-bottom: 12px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    color: $grey-4;
    max-width: 338px;
    width: 100%;
  }
}

.worker {
  background: $blue-50;
  padding-top: 148px;
  padding-bottom: 131px;
  &-1 {
    margin-top: -200px;
    margin-bottom: -236px;
    position: relative;
    &:before {
      content: '';
      top: -82.5px;
      left: 50%;
      transform: translateX(-50%);
      background: url(../../img/stick.png) no-repeat center;
      width: 165.09px;
      height: 181.19px;
      position: absolute;
    }
  }
  &-2 {
    right: 0;
    z-index: 2;
    position: absolute;
    bottom: -351px;
    margin-right: -164px;
    &:before {
      content: '';
      top: -82.5px;
      left: 50%;
      transform: translateX(-50%);
      background: url(../../img/stick-2.png) no-repeat center;
      width: 165.09px;
      height: 181.19px;
      position: absolute;
    }
  }

  .title {
    letter-spacing: 1px;
    margin-bottom: 21px;
  }
  p {
    font-size: 24px;
    line-height: 38px;
    letter-spacing: 1px;
    color: $grey-4;
    max-width: 500px;
    width: 100%;
    margin-bottom: 36px;
  }
}

.adaptive {
  padding-top: 357px;
  padding-bottom: 144px;
  &-slider {
    img {
      max-height: 610px;
    }
    display: none;
    .swiper-slide {
      text-align: center;
    }
    &.active {
      display: block;
    }
  }
  &-list {
    li {
      cursor: pointer;
      margin-bottom: 29px;
      font-size: 24px;
      line-height: 36px;
      color: $grey-1;
      transition: 0.7s color ease;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        opacity: 0;
        content: '';
        position: absolute;
        left: -52px;
        top: 0;
        width: 36px;
        height: 36px;
        background: url(../../img/arrow-cursor.svg) no-repeat center;
        background-size: cover;
        transition: 0.7s opacity ease;
      }
      &.active {
        font-weight: bold;
        color: $blue-500;
        &:before {
          opacity: 1;
        }
      }
    }
    &-holder {
      padding-left: 52px;
      .title {
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        letter-spacing: 1px;
        color: $grey-1;
        margin-bottom: 45px;
      }
    }
  }
  #laptop {
    margin-right: -250px;
  }
  .title {
    font-size: 48px;
    line-height: 58px;
    letter-spacing: 1px;
    color: $grey-1;
    margin-bottom: 25px;
  }
  &-type {
    margin-bottom: 112px !important;
    font-size: 15px;
    line-height: 15px;
    border-bottom: 1px solid #ebebeb;
    display: inline-flex;
    li {
      position: relative;
      cursor: pointer;
      padding: 18px 27.5px;
      color: $grey-1;
      &:after {
        opacity: 0;
        height: 4px;
        content: '';
        bottom: 0;
        background: $blue-500;
        border-radius: 4px 4px 0px 0px;
        width: 80%;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        transition: 0.3s opacity ease;
      }
      &.active {
        font-weight: 500;
        color: $blue-500;
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.additional {
  padding-top: 139px;
  padding-bottom: 92px;
  background: $blue-50;

  .title {
    font-weight: bold;
    font-size: 48px;
    line-height: 94px;
    color: $blue-300;
  }

  .subtitle {
    font-size: 36px;
    line-height: 54px;
    color: $grey-4;
    max-width: 573px;
    width: 100%;
    margin: 0 auto;
  }

  .leave {
    margin-top: 75px;
    font-size: 36px;
    line-height: 54px;
    color: $blue-500;
    margin-bottom: 20px;
  }
}
.stages-container {
  display: inline-flex;
  position: relative;
  max-width: 1312px;
  width: 100%;
  padding-left: 86px;
  padding-right: 86px;
}
.stages-navigation {
  z-index: 4;
  width: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  span {
    cursor: pointer;
    pointer-events: all;
  }
  .stages-prev {
    position: absolute;
    left: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .stages-next {
    position: absolute;
    right: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
}

#stages {
  position: relative;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  background: $white;
  box-shadow: 0px 10.4871px 20.9741px rgba(10, 52, 79, 0.16);
  border-radius: 4px;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 3;
    width: 2px;
    background: $white;
  }
  .swiper-slide {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -1px;
      bottom: 0;
      border-right: 1px solid $grey-2;
    }
    & > div {
      padding: 40px 41px;
      text-align: left;
      min-height: 360px;
      p {
        font-size: 16px;
        line-height: 24px;
        color: $grey-4;
      }
    }
    .date {
      font-size: 14px;
      line-height: 18px;
      color: $grey-4;
    }
    .title {
      margin-bottom: 12px;
      min-height: 70px;
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
      color: $grey-1;
    }
  }
}
@media (max-width: 1600px) {
  .worker-1 {
    max-width: 80%;
  }
  .worker-2 {
    margin-right: -2%;
    max-width: 57%;
  }
}

@media (max-width: 1199.98px) {
  .adaptive {
    .title {
      font-size: 34px;
      line-height: 47px;
    }
    &-list {
      li {
        font-size: 20px;
        line-height: 36px;
        margin-bottom: 17px;
        &:before {
          left: -40px;
          width: 29px;
          height: 29px;
        }
      }
    }
    &-slider {
      img {
        max-height: 420px;
      }
    }
  }

  .worker-2 {
    display: none;
  }
  .h1 {
    font-size: 58px;
    line-height: 66px;
  }
  header {
    .logo {
      margin-right: 11px;
    }
    .btn-primary {
      margin-left: 11px;
    }
    ul {
      li {
        > a:not(.btn) {
          line-height: 20px;
          width: 100%;
          display: block;
          padding: 0 11px;
        }
      }
    }
  }
  .usage {
    padding-top: 50px;
    padding-bottom: 100px;
  }
}

@media (max-width: 991.98px) {
  .stages-container {
    padding-left: 40px;
    padding-right: 40px;
  }
  .adaptive {
    &-list {
      li {
        font-size: 18px;
        line-height: 23px;
        &:before {
          height: 23px;
          width: 23px;
        }
      }
      &-holder {
        padding-left: 17px;
      }
    }

    .title {
      font-size: 23px;
      line-height: 32px;
    }
  }
  .worker {
    padding: 50px 0;
  }
  .worker-1 {
    margin: 0;
    max-width: 100%;
  }
  .worker-2 {
    display: none;
  }
  .usage {
    .title {
      margin-bottom: 40px;
    }
  }
  .h1 {
    font-size: 47px;
    line-height: 54px;
  }
  header {
    background: $white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    svg {
      path[fill='#010202'] {
        fill: #010202;
      }
      path[fill='#187DBD'] {
        fill: $blue-500;
      }
    }
    .btn-primary {
      color: $white !important;
      background: linear-gradient(
        114.03deg,
        $blue-400 0%,
        $blue-500 100%
      ) !important;
      border-color: $blue-400 !important;
    }
    .login-btn {
      > a:not(.btn) {
        color: $blue-500;
      }
    }

    nav.d-flex {
      justify-content: space-between !important;
    }
    &.opened {
      .header-menu {
        height: calc(100% - 58px);
      }
    }
    .header-menu {
      height: 0;
      background: $white;
      position: fixed;
      top: 58px;
      left: 0;
      right: 0;
      transform: translateZ(0);
      overflow: hidden;
      transition: 0.4s height ease-out;
    }

    ul {
      overflow-y: auto;
      text-align: left;
      padding: 40px 20px !important;

      flex-direction: column !important;
      li {
        width: 100%;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }

        > a:not(.btn) {
          line-height: 26px;
          color: $grey-1;
          font-size: 16px;
          &:hover {
            color: $blue-300 !important;
          }
        }
      }
    }
  }
  .additional {
    padding: 50px 0;
    .title {
      font-size: 32px;
      line-height: 58px;
    }
    .subtitle {
      font-size: 24px;
      line-height: 38px;
    }
    .leave {
      margin-top: 45px;
      font-size: 29px;
      line-height: 39px;
    }
  }
}
@media (max-width: 767.98px) {
  footer {
    padding: 20px;
    ul {
      li {
        margin-right: 20px;
      }
    }
  }
  .download-section {
    padding: 50px 0;
  }
  .bottom-img {
    display: none;
  }
  #stages {
    max-width: 400px;
    margin: 0 auto;
  }
  .additional {
    .title {
      font-size: 28px;
      line-height: 47px;
    }
    .subtitle {
      font-size: 18px;
      line-height: 29px;
    }
    .leave {
      margin-top: 41px;
      font-size: 20px;
      line-height: 28px;
    }
  }
  .adaptive {
    padding-top: 50px;
    padding-bottom: 50px;
    &-type {
      text-align: center;
      margin-bottom: 30px !important;
      li {
        padding: 13px 8.5px;
      }
    }
    #laptop {
      margin-right: 0;
    }
    .row {
      flex-direction: column !important;
    }
    &-list-holder {
      padding-left: 52px;
      margin-bottom: 20px;
    }
    [class*='col-'] {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .worker-1 {
    margin: 0 auto;
    margin-top: 50px;
    max-width: 400px;
    width: 100%;
  }
  .worker {
    padding: 50px 0;
    p {
      font-size: 18px;
      line-height: 26px;
    }
    .row {
      flex-direction: column !important;
    }
    .col-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .usage {
    padding: 50px 0;
    svg {
      max-width: 35px;
    }
    .title {
      margin-bottom: 20px;
    }
    &-holder {
      margin-bottom: 50px;
    }
    .usage-title {
      font-size: 18px;
      line-height: 25px;
    }
    p {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .without-paper {
    padding: 50px 0;
    .text {
      font-size: 19px;
      line-height: 30px;
    }
  }
  .main {
    min-height: initial;
    padding-bottom: 25%;
  }
  .h1 {
    font-size: 29px;
    line-height: 40px;
  }
  .h2 {
    font-size: 24px;
    line-height: 39px;
  }

  .texture-laptop {
    img {
      width: 80%;
      margin-top: -20%;
    }
  }
}

@media (max-width: 476.98px) {
  .eu-donated {
    font-size: 12px;
  }
  #stages {
    .swiper-slide {
      > div {
        padding: 20px;
        min-height: initial;
      }
    }
  }
  .h1 {
    font-size: 25px;
    line-height: 34px;
  }
  .without-paper {
    .text {
      font-size: 17px;
      line-height: 24px;
    }
  }
}
