$grey-1: #363636;
$grey-2: #b0b0b1;
$grey-4: #7c7c7d;

$blue-50: #f3f6fd;
$blue-300: #63adf2;
$blue-400: #0093ee;
$blue-500: #187dbd;

$white: #ffffff;
$header-lg-height: 58px;

$lg: 1200px;
